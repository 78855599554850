/* eslint-disable react/no-array-index-key */
/* eslint-disable prettier/prettier */
/* eslint-disable import/named */
/* eslint-disable react/react-in-jsx-scope */
import Moment from 'react-moment';
import { Form } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { Utils, Options, RouterPath } from '@common';
import { images } from '@assets';
import { CustomSelect } from '@components';

// 로그아웃
import { store } from '@common/redux/store';
import { authAction } from '@reducers/authSlice';
import VerticalBarChart from '../VerticalBarChartFullDash';
import LineChartFullDash from '../LineChartFullDash';
import WeatherIconDisplay from './WeatherIconDisplay';

export default function BoardTitle(props) {
  const history = useHistory();

  const {
    chartData,
    barChartData,
    mapModeId,
    setMapModeId,
    selectedTime,
    setSelectedTime,
    directionType,
    setDirectionType,
    socketState,
    labels,
    mapTypeId,
    currentZoomLevel,
    maxZoomLevel,
    directShow,
    setDirectShow,
    heatmapType,
    setHeatmapType,
  } = props;

  const {
    pty_obsr_value: ptyObsrValue,
    pty_result_code: ptyResultCode,
    sky_fcst_value: skyFcstValue,
    sky_result_code: skyResultCode,
    weather_point_id: weatherPointId,
  } = socketState.nowWeather ?? {
    pty_obsr_value: 0,
    pty_result_code: '03',
    sky_fcst_value: 0,
    sky_result_code: '03',
    weather_point_id: 1,
  };

  return (
    <div
      id="map"
      style={{ width: '100%', height: '100vh' }}
      className="map-hidden prevent-user-select"
    >
      <div className="dashboard-main">
        <header className="dashboard-header">
          <div className="board-inner">
            <button
              type="button"
              className="btn-default-view"
              onClick={() => history.push(RouterPath.slash)}
            >
              <i style={{ backgroundImage: `url(${images.min})` }} />
              기본보기
            </button>
            <button
              type="button"
              className="btn-logout"
              onClick={() => {
                store.dispatch(authAction.removeAuth());
              }}
            >
              로그아웃
            </button>
          </div>
        </header>
        <div className="dashboard-flex-container">
          <div className="left-container">
            <div className="board-inner">
              <h1 className="dashboard-title">
                <div
                  className=""
                  style={{
                    color:
                      mapTypeId === 'satellite' || mapTypeId === 'hybrid'
                        ? '#2cf2ff'
                        : '',
                    textShadow:
                      mapTypeId === 'satellite' || mapTypeId === 'hybrid'
                        ? '2px 2px 2px gray'
                        : '',
                  }}
                >
                  창원시
                </div>{' '}
                <span
                  style={{
                    color:
                      mapTypeId === 'satellite' || mapTypeId === 'hybrid'
                        ? 'white'
                        : '',
                    textShadow:
                      mapTypeId === 'satellite' || mapTypeId === 'hybrid'
                        ? '2px 2px 2px gray'
                        : '',
                  }}
                >
                  유동인구 현황
                </span>
                <br />
                <div className="info-box">
                  <div className="clock-box">
                    <Moment
                      fromNow
                      interval={1}
                      local="ko"
                      format="YYYY.MM.DD(ddd) HH:mm:ss"
                      className="clock"
                      style={{
                        color:
                          mapTypeId === 'satellite' || mapTypeId === 'hybrid'
                            ? 'white'
                            : '',
                        textShadow:
                          mapTypeId === 'satellite' || mapTypeId === 'hybrid'
                            ? '2px 2px 2px gray'
                            : '',
                      }}
                    />
                  </div>
                  {/* 날씨 아이콘 표시 */}
                  <div className="weather-box">
                    <WeatherIconDisplay
                      ptyValue={ptyObsrValue}
                      skyValue={skyFcstValue}
                      // eslint-disable-next-line react/jsx-boolean-value
                      error={
                        !(skyResultCode === '00' && ptyResultCode === '00')
                      }
                      size={50}
                      color={
                        mapTypeId === 'satellite' || mapTypeId === 'hybrid'
                          ? 'white'
                          : 'grey'
                      }
                      fontSize={16}
                      className="weather"
                    />
                  </div>
                </div>
              </h1>
              {currentZoomLevel < maxZoomLevel ? null : (
                // 시각화
                <div className="map-visual">
                  <div className="left-nav-container">
                    <nav className="accordian-menus">
                      <div className="menu-item">
                        <button
                          type="button"
                          className="accordian-btn"
                          style={
                            mapModeId === 'NORMAL'
                              ? { color: '#2cf2ff' }
                              : { color: '#fff' }
                          }
                        >
                          <span
                            onClick={() => {
                              setMapModeId('NORMAL');
                            }}
                          >
                            {Options.visual.map(
                              item => item.value === 'equipment' && item.label,
                            )}
                          </span>
                        </button>
                      </div>
                      <div className="menu-item">
                        <button
                          type="button"
                          className="accordian-btn"
                          style={
                            mapModeId === 'HEATMAP'
                              ? { color: '#2cf2ff' }
                              : { color: '#fff' }
                          }
                        >
                          <span
                            onClick={() => {
                              setMapModeId('HEATMAP');
                            }}
                          >
                            {Options.visual.map(
                              item =>
                                item.value === 'distribution' && item.label,
                            )}
                          </span>
                        </button>
                      </div>
                      <div className="menu-item">
                        <button
                          type="button"
                          className="accordian-btn"
                          style={
                            mapModeId === 'DIRECTION'
                              ? { color: '#2cf2ff' }
                              : { color: '#fff' }
                          }
                        >
                          <span
                            onClick={() => {
                              setMapModeId('DIRECTION');
                            }}
                          >
                            {Options.visual.map(
                              item => item.value === 'direction' && item.label,
                            )}
                          </span>
                        </button>
                      </div>
                    </nav>
                    {mapModeId === 'HEATMAP' ? (
                      <div
                        className="direcion-choice direction-container"
                        style={{
                          backgroundColor: `rgba(0, 16, 47, 0.8)`,
                        }}
                      >
                        <span className="direcion-span direction-choice-1">
                          <Form.Check
                            type="radio"
                            id="heatmap-type-a"
                            name="heatmap-check"
                            checked={heatmapType === 'type-a'}
                            onChange={() => {
                              setHeatmapType('type-a');
                            }}
                          />
                          <Form.Label
                            htmlFor="heatmap-type-a"
                            style={
                              heatmapType === 'type-a'
                                ? { color: '#2cf2ff' }
                                : { color: '#fff' }
                            }
                          >
                            밀집도A
                          </Form.Label>
                        </span>
                        {selectedTime.value === 'now' && (
                          <span className="direcion-span direction-choice-2">
                            <Form.Check
                              type="radio"
                              id="heatmap-type-b"
                              name="heatmap-check"
                              checked={heatmapType === 'type-b'}
                              onChange={() => {
                                setHeatmapType('type-b');
                              }}
                            />
                            <Form.Label
                              htmlFor="heatmap-type-b"
                              style={
                                heatmapType === 'type-b'
                                  ? { color: '#2cf2ff' }
                                  : { color: '#fff' }
                              }
                            >
                              밀집도B
                            </Form.Label>
                          </span>
                        )}
                      </div>
                    ) : null}
                    {mapModeId === 'DIRECTION' ? (
                      <div
                        className="direcion-choice direction-container"
                        style={{
                          backgroundColor: `rgba(0, 16, 47, 0.8)`,
                        }}
                      >
                        <span className="direcion-span direction-choice-1">
                          <Form.Check
                            type="radio"
                            id="walk-way"
                            name="way-check"
                            checked={directionType === 'WALKING'}
                            onChange={() => {
                              setDirectionType('WALKING');
                            }}
                          />
                          <Form.Label
                            htmlFor="walk-way"
                            style={
                              directionType === 'WALKING'
                                ? { color: '#2cf2ff' }
                                : { color: '#fff' }
                            }
                          >
                            보행
                          </Form.Label>
                        </span>
                        <span className="direcion-span direction-choice-2">
                          <Form.Check
                            type="radio"
                            id="vehicle-way"
                            name="way-check"
                            checked={directionType === 'RIDING'}
                            onChange={() => {
                              setDirectionType('RIDING');
                            }}
                          />
                          <Form.Label
                            htmlFor="vehicle-way"
                            style={
                              directionType === 'RIDING'
                                ? { color: '#2cf2ff' }
                                : { color: '#fff' }
                            }
                          >
                            이동수단
                          </Form.Label>
                        </span>
                      </div>
                    ) : null}
                    {mapModeId === 'DIRECTION' ? (
                      <div
                        className="direcion-choice direction-container"
                        style={{
                          backgroundColor: `rgba(0, 16, 47, 0.8)`,
                        }}
                      >
                        <span className="direcion-span direction-choice-1">
                          <Form.Check
                            type="radio"
                            id="direction-show-one"
                            name="show-check"
                            checked={directShow === 'ONE'}
                            onChange={() => {
                              setDirectShow('ONE');
                            }}
                          />
                          <Form.Label
                            htmlFor="direction-show-one"
                            style={
                              directShow === 'ONE'
                                ? { color: '#2cf2ff' }
                                : { color: '#fff' }
                            }
                          >
                            개별
                          </Form.Label>
                        </span>
                        <span className="direcion-span direction-choice-2">
                          <Form.Check
                            type="radio"
                            id="direction-show-all"
                            name="show-check"
                            checked={directShow === 'ALL'}
                            onChange={() => {
                              setDirectShow('ALL');
                            }}
                          />
                          <Form.Label
                            htmlFor="direction-show-all"
                            style={
                              directShow === 'ALL'
                                ? { color: '#2cf2ff' }
                                : { color: '#fff' }
                            }
                          >
                            전체
                          </Form.Label>
                        </span>
                      </div>
                    ) : null}
                  </div>
                </div>
              )}
              <div className="left-bottom-container">
                <div className="bottom-container-left">
                  <p className="title">구축 장비 현황</p>
                  <div className="sensor-situation">
                    <div className="sensor-title">
                      <span>전체</span>
                      <span className="normal">정상</span>
                      <span className="error">장애</span>
                    </div>
                    <div className="divide-line" />
                    <div className="sensor-num">
                      <span>
                        {'visitor' in socketState
                          ? Utils.changeNumberComma(
                              socketState.equipState.total,
                            )
                          : 0}
                      </span>
                      <span>
                        {'visitor' in socketState
                          ? Utils.changeNumberComma(
                              socketState.equipState.normal,
                            )
                          : 0}
                      </span>
                      <span>
                        {'visitor' in socketState
                          ? Utils.changeNumberComma(
                              socketState.equipState.abnormal,
                            )
                          : 0}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="bottom-container-right">
                  <p className="title">방문객 수 누계</p>
                  <p className="visitorcount-period">
                    ({socketState.visitor?.date?.replaceAll('-', '.')} ~{' '}
                    {socketState.visitor?.updateDate
                      ?.slice(5, 10)
                      .replaceAll('-', '.')}{' '}
                    기준)
                  </p>
                  <div className="visitor-total-count">
                    {'visitor' in socketState
                      ? Utils.changeNumberComma(
                          Number(socketState.visitor?.count),
                        )
                      : 0}
                    <span>명</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* left-container-end */}
          <div className="right-container">
            <div className="board-inner">
              <div className="population-situation">
                <div className="status">
                  <p className="title">유동인구현황</p>
                  <CustomSelect
                    options={Options.time}
                    value={selectedTime}
                    onChange={setSelectedTime}
                    className="mw-auto"
                  />
                </div>
                <h3>
                  {'human' in socketState
                    ? Utils.changeNumberComma(Number(socketState.human?.count))
                    : 0}
                  <span>명</span>
                </h3>
              </div>
              <div className="today-graph">
                <p className="title">금일 유동인구 누계</p>
                <div style={{ width: '100%', height: '90%' }}>
                  <LineChartFullDash data={chartData} />
                </div>
              </div>
              <div className="top-graph">
                <p className="title">유동인구 상위지역</p>
                <div>
                  <VerticalBarChart barChartData={barChartData} />
                </div>
                <div className="title-labels">
                  {labels.map((option, idx) => (
                    <div key={idx} className="oplabel-flex-full">
                      <span className={`option-boll ${option.color}`} />
                      <Form.Label>{option.label}</Form.Label>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
          {/* right-container-end */}
        </div>
      </div>
    </div>
  );
}
